
import { Options, Vue } from 'vue-class-component';
import { BackofficeLocalizationClient, LocalizationClient, SurveyConfigClient } from '@/services/Services';
import * as OM from '@/Model';
import BaseServerFilterTable from '@/components/customTable/baseServerFilterTable';
import store from '@/store';
import DictionaryEditModal from '../modal/dictionaryEditModal.vue';

@Options({
    components: {
    }
})
export default class surveyList extends BaseServerFilterTable {

    list: OM.SurveyConfigVM[] = [];
    tableRoot = null;

    init = function(){
        SurveyConfigClient.getAll(this.requestFilter)
        .then(x => {
            this.list = x.items;
            this.totalItems = x.totalItemsCount;
        })
    }

    // ricalcola(){
    //     SurveyConfigClient.recalculateSurveysForUsers()
    //     .then(x => {
    //     })
    // }
}
